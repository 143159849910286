<template>
  <div id="farmersamples">
    <!-- week lists -->
    <div :class="'d-flex flex-column ' + (isMobile ? '' : 'sample-container')">
      <title-pond></title-pond>

      <template v-if="isMinnowtech">
        <div class="mt-5 mb-0 pb-0">
          <pond-menu></pond-menu>
        </div>
      </template>

      <v-row style="flex: 0 0 50px">
        <v-col :cols="isMobile ? 12 : 2" class="d-flex pl-5 align-center">
          <v-checkbox
            hide-details
            v-model="isCompleted"
            @change="changeCompleted()"
          >
					<template v-slot:label>
						<span style="font-size: 15px;" class="pondinput">Harvested On:</span>
					</template>
				</v-checkbox>
				</v-col>
				<v-col :cols="isMobile ? 12 : 2" class="d-flex pl-0 align-center">
					<v-menu
							v-model="menu2"
							:close-on-content-click="true"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="completed"
									v-bind="attrs"
									v-on="on"
									
								></v-text-field>
							</template>
							<v-date-picker
								v-model="completed"
								@input="menu2 = false"
								@change="partialUpdate('completed')"
							></v-date-picker>
						</v-menu>
				</v-col>
			</v-row>

      <v-row style="flex: 0 0 50px; font-size: 13px">
        <v-col :cols="isMobile ? 12 : 5" class="d-flex pl-10 align-center">
          <span class="pondcarrot pr-2"></span>
          <span class="pondinput">Total Harvested Amount:</span>
				</v-col>
				<v-col :cols="isMobile ? 12 : 7" class="d-flex pa-0 align-center">
          <v-text-field
            label=""
            v-model="harvested"
            placeholder=""
						solo
            class="mt-0 text-right input-yellow shrimpsize pr-2 text-white"
            @change="partialUpdate('harvested')"
          >
          </v-text-field>
          lbs
					<div class="flex-grow-1"></div>
				</v-col>
			</v-row>

			<v-row style="flex: 0 0 50px; font-size: 13px">
        <v-col :cols="isMobile ? 12 : 5" class="d-flex pl-10 align-center">
          <span class="pondcarrot pr-2"></span>
          <span class="pondinput">Shrimp Weight at Harvest From Farm:</span>
				</v-col>
				<v-col :cols="isMobile ? 12 : 7" class="d-flex pa-0 align-center">
          <v-text-field
            label=""
            v-model="avgweight"
            placeholder=""
						solo
            class="mt-0 text-right input-yellow shrimpsize pr-2 text-white"
            @change="partialUpdate('avgweight')"
          >
          </v-text-field>
           g
          <div class="flex-grow-1"></div>
        </v-col>
			</v-row>

			<v-row style="flex: 0 0 50px; font-size: 13px" class="pb-5">
        <v-col :cols="isMobile ? 12 : 5" class="d-flex pl-10 align-center">
          <span class="pondcarrot pr-2"></span>
          <span class="pondinput">Shrimp Weight Observed at Harvest From Processor:</span>
				</v-col>
				<v-col :cols="isMobile ? 12 : 2" class="d-flex pa-0 align-center">
          <v-text-field
            label=""
            v-model="avgweightobs"
            placeholder=""
						solo
            class="mt-0 text-right input-yellow shrimpsize pr-2 text-white"
            @change="partialUpdate('avgweightobs')"
          >
          </v-text-field>
          g
          <div class="flex-grow-1"></div>
        </v-col>

        <v-col :cols="isMobile ? 12 : 5" class="text-right py-0 d-flex justify-end align-center v-messages" >
          {{ page }}/{{ pages }} Pages
        </v-col>
      </v-row>

      <v-row class="flex-grow-1 flex-shrink-1 scroll full-height">
        <v-simple-table class="mt-0">
          <template #default>

            <thead>
              <tr>
                <th class="border-right text-center custom-header">
									Result Date
								</th>
                <th class="text-center custom-header">
									Weight Sample Date
								</th>
                <th class="border-right text-center custom-header">
                  Individual Shrimp Weight (g)
                </th>

                <!-- Minnowtech or CountViewAllowed -->
                <th v-if="isMinnowtech || isCountViewAllowed" class="text-center custom-header">
                  Total Shrimp Count
                </th>
								<!-- Minnowtech or CountViewAllowed -->

                <th class="text-center custom-header">Total Pond Biomass (kg)</th>
                <th class="text-center custom-header">Total Pond Biomass (lbs)</th>
                <th class="text-center custom-header"></th>
								<th class="text-center custom-header"></th>
              </tr>
            </thead>

            <tbody>

              <template v-if="loadingStatus">
                <tr>
                  <td class="nodata" colspan="7" align="center">
                    <v-progress-circular
                      indeterminate
                      color="amber"
                    ></v-progress-circular>
                  </td>
                </tr>
              </template>
              <template v-else-if="!isReleased">
                <tr>
                  <td class="nodata" colspan="7" align="center">
                    Shrimp data will be released from Friday of Week 2.
                  </td>
                </tr>
              </template>
              <template v-else-if="!samples_list.length">
                <tr>
                  <td class="nodata" colspan="7" align="center">
                    Results will be updated soon
                  </td>
                </tr>
              </template>

              <template v-else v-for="item in samples_list">
                <template>
                  <tr :key="item.id">

										<!-- Result Date Col -->
                    <td class="text-left">
                      <div class="text-center">
                        {{ getWeekDate(item.result_date) }}
                      </div>
                    </td>

										<!-- Weight Sample Date Col -->
                    <!-- Minnowtech Side -->
                    <template v-if="isMinnowtech">
                      <td>
                        <div v-show="item.weight_date" class="text-center">
                          {{ getWeekDate(item.weight_date) }}
                        </div>
                      </td>
                    </template>
                    <!-- Farmer Side -->
                    <template v-else>
                      <td>
                        <div class="d-flex align-center pl-0 justify-center">
                          <v-menu
                            v-model="item.menu3"
                            :close-on-content-click="true"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="item.weight_date"
                                v-bind="attrs"
                                v-on="on"
                                class="weightdate"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.weight_date"
                              @input="item.menu3 = false"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </td>
                    </template>
										
										<!-- Individual Shrimp Weight Col-->
                    <td>
                      <div class="d-flex align-center pl-0 justify-center">
												<!-- Minnowtech Side -->
                        <template v-if="isMinnowtech">
                          <td v-show="item.weight" class="text-center">
                            <div class="shrimp">
                              {{ item.weight }}
                            </div>
                          </td>
                        </template>

                        <!-- Farmer Side -->
                        <template v-else>
                          <v-text-field
                            label=""
                            v-model="item.weight"
                            placeholder=""
														solo
														class="flex-grow-1 mt-0 text-right input-yellow shrimpsize text-white"
                          >
                          </v-text-field>
                        </template>
                      </div>
                    </td>
										
                    <!-- Minnowtech Side -->
                    <template v-if="isMinnowtech">
											<!-- Total Shrimp Count Col -->
                      <td>
                        <div class="d-flex align-center pl-0 justify-center">
                          <v-text-field
                            label=""
                            v-model="item.count"
                            solo
                            class="flex-grow-1 mt-0 text-right input-yellow shrimpsize text-white"
                          >
                          </v-text-field>
                        </div>
                      </td>
											
											<!-- Biomass Kg Col -->
											<td>
												<router-link to="/farmerreport?type=biomasskg">
													<div class="shrimp" v-show="item.weight">
														{{
															getBiomassKilo(item.count, item.weight)
																| formatComma
														}}
													</div>
												</router-link>
											</td>

											<!-- Biomass Lbs Col -->
											<td class="text-right">
												<router-link to="/farmerreport?type=biomass">
													<div class="shrimp" v-show="item.weight">
														{{
															getBiomass(item.count, item.weight)
																| formatComma
														}}
													</div>
												</router-link>
											</td>

                    </template>

                    <!-- CountViewAllowed Side -->
                    <template v-else-if="isCountViewAllowed">

											<!-- Total Shrimp Count Col -->
                      <td v-if="item.weight && item.weight_date" class="text-right">
                        <div class="shrimp">
                          {{ item.count | formatComma }}
                        </div>
                      </td>
											
                      <!-- Placeholder for biomass -->
                      <template v-if="!item.weight || !item.weight_date">
                        <td colspan="3" class="text-center">
                          Please input shrimp weight and date to see shrimp count and biomass
                        </td>
                      </template>
											
                      <template v-else-if="item.weight && item.weight_date">
                        <!-- Biomass Kg Col -->
                        <td>
                          <router-link to="/farmerreport?type=biomasskg">
                            <div v-if="item.weight && item.weight_date" class="shrimp">
                              {{
                                getBiomassKilo(item.count, item.weight)
                                  | formatComma
                              }}
                            </div>
                          </router-link>
                        </td>
                        <!-- Biomass Lbs Col -->
                        <td class="text-right">
                          <router-link to="/farmerreport?type=biomass">
                            <div v-if="item.weight && item.weight_date" class="shrimp">
                              {{
                                getBiomass(item.count, item.weight)
                                  | formatComma
                              }}
                            </div>
                          </router-link>
                        </td>
                      </template>

										</template>

										<!-- Farmer Side -->
										<template v-else>
											<!-- Biomass Kg Col -->
											<td>
												<router-link to="/farmerreport?type=biomasskg">
													<div class="shrimp" v-show="item.weight">
														{{
															getBiomassKilo(item.count, item.weight)
																| formatComma
														}}
													</div>
												</router-link>
											</td>

											<!-- Biomass Lbs Col -->
											<td class="text-right">
												<router-link to="/farmerreport?type=biomass">
													<div class="shrimp" v-show="item.weight">
														{{
															getBiomass(item.count, item.weight)
																| formatComma
														}}
													</div>
												</router-link>
											</td>
										</template>

										<!-- Action Buttons Col -->
										<td>
											<v-btn
												small
												color="primary"
												class="flex-grow-1 update"
												@click="updateResults(item)"
											>
												Save
											</v-btn>
										</td>
										<td>
											<template v-if="isMinnowtech">
												<v-btn small color='error' @click="deleteResults(item)">
													Delete
												</v-btn
												>
											</template>
										</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>

      <v-row style="flex: 0 0 55px" class="justify-center pt-3">
        <v-pagination
          v-model="page"
          :length="pages"
          @input="handlePageChange"
          circle
        ></v-pagination>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import TitlePond from "@/components/TitlePond";
import tokenAxios from "@/components/tokenAxios";
import Swal from "sweetalert2";

import moment from "moment";
import { validationMixin } from "vuelidate";
import { decimal, required } from "vuelidate/lib/validators";
import PondMenu from "./PondMenu";

export default {
  name: "FarmerSamples",
  computed: {
    ...mapState([
      "isMobile",
      "user_info",
      "isMinnowtech",
      "isCountViewAllowed",
      "pond",
      "loadingStatus",
    ]), // get user_info and pond from store
    // get Pond's name with WOC
    getPond() {
      const diff = (
        this.pond && this.completed ? moment(this.completed) : moment()
      ).diff(moment(this.pond.date), "days");
      const woc = Math.floor((diff - 1) / 7) + 1;

      if (this.pond)
        return `${this.pond.farm_name}, Pond ${
          this.pond.pond_id
        } : ${this.$options.filters.formatDate(
          this.pond.date
        )} - ${diff} days (W${woc})`;
      else return "";
    },
    // Pond data will be released from WOC 3 Fri
    isReleased() {
      const diff = moment().diff(moment(this.pond.date), "days");
      const woc = Math.floor((diff - 1) / 7) + 1;
      this.$debug("isReleased", woc, moment().format("ddd"));
      if (woc < 3 && moment().format("ddd") !== "Fri") return false;
      else return true;
    },
    completed: {
      get() {
        return this.pond.completed;
      },
      set(value) {
        this.$store.commit("completed", value);
      },
    },
    harveststarted: {
      get() {
        return this.pond.harveststarted;
      },
      set(value) {
        this.$store.commit("harveststarted", value);
      },
    },
    harvested: {
      get() {
        return this.pond.harvested;
      },
      set(value) {
        this.$store.commit("harvested", value);
      },
    },
    avgweight: {
      get() {
        return this.pond.avgweight;
      },
      set(value) {
        this.$store.commit("avgweight", value);
      },
    },
    avgweightobs: {
      get() {
        return this.pond.avgweightobs;
      },
      set(value) {
        this.$store.commit("avgweightobs", value);
      },
    },
  },
  mixins: [validationMixin],
  validations: {
    assigned: {},
    pond: { required },
    week: { required },
    size: { required, decimal },
    temperature: { required, decimal },
    oxygen: { required, decimal },
    salinity: { required, decimal },
  },
  components: {
    PondMenu,
    TitlePond,
  },
  data: () => ({
    API_SERVER: process.env.VUE_APP_API_SERVER,
    isCompleted: false,
    page: 1,
    pages: 1,
    samples_list: [],
    sample_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10), // Add Sample
    status: 0,
    assigned: "",
    week: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    size: null,
    temperature: null,
    oxygen: null,
    salinity: null,
    note: "",
    totalHarvested: 0,
    snackbar: false,
    snackbar_text: "",
    menu2: false,
    menu3: false,
    showCount: false
  }),
  created() {
    this.$debug("created", this.pond);
    if (!this.pond) this.$router.push("/ponds");
    this.listSamples();
  },
  mounted() {
    if (this.pond) this.isCompleted = this.completed;
  },
  methods: {
    // Get week date from WOC
    getWeekDate(d) {
      return moment(d).format("DD/MM/YYYY");
    },

    // Error Handling
    fieldErrors(field) {
      this.$debug("fieldErrors", field, this.$v, this.$v[field]);
      if (!this.$v[field].$dirty || !this.$v[field].$error) return [];
      else if (!this.$v[field].decimal) return ["Number is required."];
      else if (!this.$v[field].required) return ["This field is required."];
    },

    // List Samples
    async listSamples() {
      try {
        this.$debug("API_SERVER", this.API_SERVER, "this.pond", this.pond);
        this.$store.commit("loadingStatus", 1);
        const response = await tokenAxios(this.user_info.access_token).get(
          this.API_SERVER + `/farm/results/?format=json&pond=${this.pond.id}`
        );
        this.$debug("get weekly results lists", response);
        if (response.status === 200 && response.data.results.length) {
          // Calculate total harvested amount
          response.data.results.map((o) => {
            try {
              if (o.harvested) this.totalHarvested += o.harvested;
            } catch (e) {
              return;
            }
          });

          this.samples_list = response.data.results;
        } else {
          this.$debug("error", response);
        }
      } catch (e) {
        this.$debug("error", e);
      } finally {
        this.$store.commit("loadingStatus", 0);
      }
    },

    // Total Pond Biomass
    getBiomass(count, weight) {
      if (weight) return Math.round((count * weight) / 453.5);
      else return "";
    },

    // Total Pond Biomass (kg)
    getBiomassKilo(count, weight) {
      if (weight) return Math.round((count * weight) / 1000);
      else return "";
    },

    // List Samples
    handlePageChange(page) {
      this.listSamples(page);
    },

    // Get week Number for the date
    getWeekNo(v_date_str) {
      const days = moment(v_date_str).diff(moment(this.pond.date), "days") + 1;
      this.$debug(
        "getWeekNo",
        moment(v_date_str),
        moment(this.pond.date),
        days,
        Math.ceil(days / 7)
      );
      return Math.ceil(days / 7);
    },

    // Change the completed date for the pond
    changeCompleted() {
      if (this.isCompleted) {
        this.completed = moment().format("YYYY-MM-DD");
        this.partialUpdate("completed");
      } else {
        this.completed = null;
        this.partialUpdate("completed");
      }
    },

    // check number
    checkNumber(o) {
      try {
        this.$debug("checkNumber", o);
        const trimmed = o.trim();
        this.$debug("trimmed", trimmed);

        if (trimmed === "") return "";
        else return parseFloat(o.trim());
      } catch (e) {
        Swal.fire(`Please Input number!`, "", "warning");
        return false;
      }
    },

    // change completed
    async partialUpdate(field) {
      try {
        this.$debug("partialUpdate", field);
        var formData = new FormData();

        if (field === "completed") {
          this.$debug("this.completed", this.completed);
          formData.append("completed", this.completed ? this.completed : "");
          this.isCompleted = this.completed;
        } else if (field === "harveststarted") {
          this.$debug(
            "this.harveststarted",
            this.harveststarted,
            this.pond.harveststarted
          );
          formData.append(
            "harveststarted",
            this.harveststarted ? this.harveststarted : ""
          );
        } else if (field === "harvested") {
          const newNum = this.checkNumber(this.harvested);

          this.$debug("newNum", newNum, "this.harvested", this.harvested);
          formData.append("harvested", newNum);
          if (newNum === false) {
            return;
          } else {
            this.harvested = newNum;
            formData.append("harvested", newNum);
          }
        } else if (field === "avgweight") {
          const newNum = this.checkNumber(this.pond.avgweight);
          if (newNum === false) {
            return;
          } else {
            this.avgweight = newNum;
            formData.append("avgweight", newNum);
          }
        } else if (field === "avgweightobs") {
          const newNum = this.checkNumber(this.pond.avgweightobs);
          if (newNum === false) {
            return;
          } else {
            this.avgweightobs = newNum;
            formData.append("avgweightobs", newNum);
          }
        } else {
          return;
        }

        this.$debug("partialUpdate_formData", formData);

        await tokenAxios(this.user_info.access_token).patch(
          process.env.VUE_APP_API_SERVER + `/farm/pond/${this.pond.id}/`,
          formData
        );

        this.$root.vtoast.show({
          message: `Week ${this.pond.pond_id} for ${this.getPond} is updated`,
        });

        // set Submitted
        this.status = 0;
        this.listSamples();
      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },

    // Submit samples to server
    async updateResults(item) {
      try {
        var formData = new FormData();
        formData.append("pond", this.pond.id);
        if (item.count) formData.append("count", item.count);
        if (item.weight) formData.append("weight", item.weight);
        if (item.harvested) formData.append("harvested", item.harvested);
        if (item.weight_date) formData.append("weight_date", item.weight_date);

        this.$debug("updateResults", formData);

        await tokenAxios(this.user_info.access_token).patch(
          process.env.VUE_APP_API_SERVER + `/farm/results/${item.id}/`,
          formData
        );
        Swal.fire(
          `Week ${item.woc} for ${this.getPond} is updated`,
          "",
          "success"
        );
        this.showCount = true

        // set Submitted
        this.status = 0;
        this.listSamples();
        this.showCount = true
      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },

    // Delete sample
    async deleteResults(item) {
      try {
        const confirm = await Swal.fire({
          text: `Do you want to delete Week ${item.woc}?`,
          confirmButtonText: "Delete",
        });

        if (confirm) {
          await tokenAxios(this.user_info.access_token).delete(
            process.env.VUE_APP_API_SERVER + `/farm/results/${item.id}/`
          );
          Swal.fire(
            `Week ${item.woc} for ${this.getPond} is deleted`,
            "",
            "success"
          );

          // set Submitted
          this.status = 0;

          // For pond menu, update results_count
          var newdata = {
            ...this.pond,
            results_count: this.pond.results_count - 1,
          };
          this.$debug("newdata", newdata);
          this.$store.commit("pond", newdata);

          this.listSamples();
        }
      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },

    // modify
    modifySample(sample) {
      sample.pond = this.pond;
      this.$debug("modifySample", sample);
      this.$router.push({
        name: "scan",
        params: {
          sample: { ...sample },
        },
      });
    },
  },
};
</script>
<style scoped>
.minnowtech {
  background-color: #eee;
}

.weekno {
  color: #07124f;
  font-weight: bold;
  margin-right: 20px;
}

.border-left {
  border-left: 1px solid #e2eaf1;
}

.border-right {
  border-right: 1px solid #e2eaf1;
}

.scroll {
  overflow: auto;
}

.scroll .v-data-table {
  width: 100%;
  max-width: none;
  position: relative;
}

.scroll th {
  position: sticky;
  top: 0px;
  z-index: 2;
}

.update {
  max-width: 70px;
  margin-left: 20px;
}

.woc-date {
  width: 150px;
  font-size: 12px;
}

/* .week {
  text-align: center;
  background-color: #4caf50;
  color: white;
  color: #1976d2;
  border-radius: 10px;
  width: 80px;
  padding: 1px 10px;
  margin: auto;
  font-size: 12px;
  font-weight: bold;
} */
.shrimp {
  text-align: center;
  border-radius: 30px;
  border: 1px solid #46BFC6;
  color: #0E7179;
  width: 100px;
  padding: 4px 10px;
  margin: auto;
  font-size: 15px;
  font-weight: bold;
}

.biomass {
  /* background-color: #f26835; */
}

.pondinput {
  line-height: 15px;
  color: rgba(0, 0, 0, 0.7);
  max-width: 350px;
}

.pondcarrot {
  margin: 0 2px 0 10px;
}

.pondcarrot:before {
  content: "🦐";
}

.harvestedon {
  margin: 20px;
  /* padding: 10px 20px; */
}

.weightdate {
  max-width: 100px;
}
</style>
<style>
.shrimpsize,
.shrimpsize.v-text-field--solo .v-input__control {
  min-width: 80px;
  max-width: 95px;
  min-height: 30px;
  border-radius: 17px;
}

input::placeholder {
  font-size: 12px;
}

.completed_date .v-picker__body {
  border-radius: 10px;
}

.completed_date .v-picker__title {
  display: none;
}

.custom-header {
  background-color: #46BFC6; 
}

.text-white input {
  color: white !important;
}
</style>
